import React from 'react';


const Search = ({ setSearchText }) => {
    const handleInput = (e) => {
        const query = e.target.value;
        setSearchText(query);
    };

    return (
        <form className='w-96 relative flex items-center ml-7 font-nunito'>
            <input
                type="text"
                name="search"
                onChange={handleInput}
                className="w-full rounded bg-gray-2 placeholder:text-gray-1 pl-2 required outline-0 border border-gray-1 focus:border-gold"
                placeholder="Search here..."
            />
        
        </form>
    );
};

export default Search;
