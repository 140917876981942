const coinSpotBybit={
    
"BTCUSDT": "Bitcoin",
"ETHUSDT": "Ethereum",
"XRPUSDT": "XRP (Ripple)",
"EOSUSDT": "EOS",
"ETHBTC": "Ethereum to Bitcoin",
"XRPBTC": "XRP to Bitcoin",
"DOTUSDT": "Polkadot",
"XLMUSDT": "Stellar",
"LTCUSDT": "Litecoin",
"DOGEUSDT": "Dogecoin",
"CHZUSDT": "Chiliz",
"AXSUSDT": "Axie Infinity",
"MANAUSDT": "Decentraland",
"DYDXUSDT": "dYdX",
"MKRUSDT": "Maker",
"COMPUSDT": "Compound",
"AAVEUSDT": "Aave",
"YFIUSDT": "yearn.finance",
"LINKUSDT": "Chainlink",
"SUSHIUSDT": "SushiSwap",
"UNIUSDT": "Uniswap",
"KSMUSDT": "Kusama",
"ICPUSDT": "Internet Computer",
"ADAUSDT": "Cardano",
"ETCUSDT": "Ethereum Classic",
"KLAYUSDT": "Klaytn",
"XTZUSDT": "Tezos",
"BCHUSDT": "Bitcoin Cash",
"SRMUSDT": "Serum",
"QNTUSDT": "Quant",
"USDCUSDT": "USD Coin",
"GRTUSDT": "The Graph",
"SOLUSDT": "Solana",
"FILUSDT": "Filecoin",
"OMGUSDT": "OMG Network",
"TRIBEUSDT": "Tribe",
"BATUSDT": "Basic Attention Token",
"ZRXUSDT": "0x",
"CRVUSDT": "Curve DAO Token",
"AGLDUSDT": "Adventure Gold",
"ANKRUSDT": "Ankr",
"PERPUSDT": "Perpetual Protocol",
"MATICUSDT": "Polygon (MATIC)",
"WAVESUSDT": "Waves",
"LUNCUSDT": "Lunar Crush",
"SPELLUSDT": "Spell Token",
"SHIBUSDT": "Shiba Inu",
"FTMUSDT": "Fantom",
"ATOMUSDT": "Cosmos",
"ALGOUSDT": "Algorand",
"ENJUSDT": "Enjin Coin",
"CBXUSDT": "Cubiex",
"SANDUSDT": "Sandbox",
"AVAXUSDT": "Avalanche",
"WOOUSDT": "Wootrade",
"FTTUSDT": "FTX Token",
"GODSUSDT": "Gods Unchained",
"IMXUSDT": "Immutable X",
"ENSUSDT": "ENS",
"GMUSDT": "Guild of Guardians",
"CWARUSDT": "CryptoWars",
"CAKEUSDT": "PancakeSwap",
"STETHUSDT": "Lido Staked Ether",
"GALFTUSDT": "Galaxy Fight Token",
"LFWUSDT": "LFW",
"SLPUSDT": "Smooth Love Potion",
"C98USDT": "Coin98",
"PSPUSDT": "PepeSwap",
"GENEUSDT": "Gene Finance",
"AVAUSDT": "Travala",
"ONEUSDT": "Harmony",
"PTUUSDT": "Plutus",
"SHILLUSDT": "Shill",
"XYMUSDT": "Symbol",
"BOBAUSDT": "Bob's Repair",
"JASMYUSDT": "JasmyCoin",
"GALAUSDT": "Gala",
"RNDRUSDT": "Render Token",
"TRVLUSDT": "Travala.com",
"WEMIXUSDT": "WEMIX",
"XEMUSDT": "NEM",
"BICOUSDT": "Biconomy",
"CELUSDT": "Celsius",
"UMAUSDT": "UMA",
"HOTUSDT": "Holo",
"NEXOUSDT": "Nexo",
"BNTUSDT": "Bancor",
"SNXUSDT": "Synthetix",
"RENUSDT": "Ren",
"1INCHUSDT": "1inch",
"TELUSDT": "Telcoin",
"SISUSDT": "sUSD",
"LRCUSDT": "Loopring",
"LDOUSDT": "Lido DAO Token",
"REALUSDT": "Realio",
"KRLUSDT": "Kryll",
"DEVTUSDT": "Dev Protocol",
"ETHUSDC": "Ethereum",
"BTCUSDC": "Bitcoin",
"1SOLUSDT": "Solana",
"PLTUSDT": "Pluton",
"IZIUSDT": "iZOBi",
"QTUMUSDT": "Qtum",
"DCRUSDT": "Decred",
"ZENUSDT": "Horizen",
"THETAUSDT": "Theta Token",
"MXUSDT": "MX Token",
"DGBUSDT": "DigiByte",
"RVNUSDT": "Ravencoin",
"EGLDUSDT": "Elrond",
"RUNEUSDT": "Thorchain",
"XLMBTC": "Stellar",
"XLMUSDC": "Stellar",
"SOLUSDC": "Solana",
"XRP": "XRP",
"ALGO": "Algorand",
"SOL": "Solana",
"RAIN": "Rainicorn",
"XEC": "Cerium",
"ICX": "ICON",
"XDC": "XinFin Network",
"HNT": "Helium",
"BTG": "Bitcoin Gold",
"ZIL": "Zilliqa",
"HBAR": "Hedera Hashgraph",
"FLOW": "Flow",
"SOS": "SOS Limited",
"KASTA": "Kasta",
"STX": "Stacks",
"SID": "SID Token",
"VPAD": "VelasPad",
"GGM": "GGM Capital",
"LOOKS": "LooksCoin",
"MBS": "MBYS",
"DAI": "Dai",
"BUSD": "Binance USD",
"ACA": "Acala",
"MV": "MVL",
"MIX": "Mixin",
"LTC": "Litecoin",
"MANA": "Decentraland",
"MATIC": "Polygon",
"DOT": "Polkadot",
"SAND": "Sandbox",
"RSS3": "RSS3",
"SYNR": "Synereo",
"TAP": "Tapmydata",
"ERTHA": "Ertha",
"GMX": "GMX",
"T": "Trust Token",
"ACH": "Alchemy Pay",
"JST": "JUST",
"SUN": "Sun Token",
"BTT": "BitTorrent",
"TRX": "Tron",
"NFT": "NFT Protocol",
"POKT": "Pokt Network",
"SCRT": "Secret",
"PSTAKE": "PStake",
"SON": "TokenStars",
"HERO": "Hero",
"DOME": "Dome Platform",
"UST": "TerraUSD",
"BNB": "Binance Coin",
"NEAR": "NEAR Protocol",
"PAXG": "Paxos Gold",
"SD": "Sable Dollar",
"APE": "ApeCoin",
"BTC3S": "3x Short Bitcoin Token",
"BTC3L": "3x Long Bitcoin Token",
"FIDA": "Bonfida",
"MINA": "Mina",
"SC": "Siacoin",
"RACA": "RACA",
"CAPS": "Capsule Coin",
"STG": "Stargaze Protocol",
"GLMR": "Glimmer",
"MOVR": "Mercurial",
"ZAM": "Zamrud",
"ETH": "Ethereum",
"BTC": "Bitcoin",
"WBTC": "Wrapped Bitcoin",
"XAVA": "Avalanche-X",
"MELOS": "Melos",
"GMT": "Mercury",
"GST": "GameStop Token",
"CELO": "Celo",
"SFUND": "Seedify.fund",
"ELT": "Elastos",
"LGX": "LGCY Network",
"APEX": "APEX Network",
"CTC": "CitiCash",
"COTI": "COTI",
"KMON": "Kmon Network",
"PLY": "Polyient Games",
"XWG": "XWorldGames",
"FITFI": "FITFI",
"STRM": "Stream Protocol",
"GAL": "Galatasaray Fan Token",
"ETH3S": "3x Short Ethereum Token",
"ETH3L": "3x Long Ethereum Token",
"KOK": "KOK Token",
"FAME": "Fame",
"XRP3S": "3x Short XRP Token",
"XRP3L": "3x Long XRP Token",
"USDD": "USDD",
"OP": "OptionRoom",
"LUNA": "Terra",
"DFI": "DeFinance",
"MOVEZ": "MOVE",
"THN": "Throne",
"DOT3S": "3x Short Polkadot Token",
"DOT3L": "3x Long Polkadot Token",
"VINU": "VinDax Coin",
"BEL": "Bella Protocol",
"FORT": "Fort",
"AVAX2S": "2x Short Avalanche Token",
"AVAX2L": "2x Long Avalanche Token",
"ADA2S": "2x Short Cardano Token",
"ADA2L": "2x Long Cardano Token",
"WLKN": "WLK Token",
"KON": "KON Token",
"LTC2S": "2x Short Litecoin Token",
"LTC2L": "2x Long Litecoin Token",
"SAND2S": "2x Short Sandbox Token",
"SAND2L": "2x Long Sandbox Token",
"OBX": "Obortech",
"SEOR": "Seor Finance",
"MNZ": "Monaize",
"CULT": "Cult Token",
"DOGE": "Dogecoin",
"EOS": "EOS",
"CUSD": "CarbonUSD",
"SLG": "Smart League",
"CMP": "Compound",
"KUNCI": "Kunci Coin",
"GST": "Gastoken",
"XETA": "Eterbase Utility Token",
"AZY": "Azbit",
"MMC": "MM Token",
"FLOKI": "Floki Inu",
"BABYDOGE": "Baby Doge Coin",
"STATUS": "Status",
"SAITAMA": "Saitama Inu",
"MATIC2S": "2x Short Polygon Token",
"MATIC2L": "2x Long Polygon Token",
"ETC2S": "2x Short Ethereum Classic Token",
"ETC2L": "2x Long Ethereum Classic Token",
"DICE": "DICE Money",
"WAXP": "WAX",
"AR": "Arweave",
"KDA": "Kadena",
"ROSE": "Oasis Network",
"SLG2S": "2x Short Smart League Token",
"SLG2L": "2x Long Smart League Token",
"APE2S": "2x Short APE Token",
"APE2L": "2x Long APE Token",
"GMT2S": "2x Short Mercury Token",
"GMT2L": "2x Long Mercury Token",
"DEFY": "DeversiFi",
"PSG": "Paris Saint-Germain Fan Token",
"BAR": "FC Barcelona Fan",
"JUV": "Juventus Fan Token",
"ACM": "AC Milan Fan Token",
"INTER": "Inter Milan Fan",
"AFC": "African Coin",
    "CITY": "Cryptocurrency",
"LINK2L": "2x Long Chainlink Token",
"LINK2S": "2x Short Chainlink Token",
"FTM2L": "2x Long Fantom Token",
"FTM2S": "2x Short Fantom Token",
"SOLO": "Sologenic",
"WBTCBTC": "Wrapped Bitcoin",
"AVAXUSDC": "Avalanche",
"ADAUSDC": "Cardano",
"OPUSDC": "Opium",
"DOGE2S": "2x Short Dogecoin Token",
"DOGE2L": "2x Long Dogecoin Token",
"ATOM2S": "2x Short Cosmos Token",
"ATOM2L": "2x Long Cosmos Token",
"APEXUSDC": "APEX Network",
"TRXUSDC": "TRON",
"ICPUSDC": "Internet Computer",
"LINKUSDC": "Chainlink",
"GMTUSDC": "Mercury",
"CHZUSDC": "Chiliz",
"SHIBUSDC": "Shiba Inu",
"LDOUSDC": "Lido DAO",
"APEUSDC": "APE Token",
"FILUSDC": "Filecoin",
"CHRP": "Chirp",
"EOS2S": "2x Short EOS Token",
"EOS2L": "2x Long EOS Token",
"WWY": "World Wildlife",
"LING": "Linker Coin",
"SWEAT": "Sweatcoin",
"DLC": "Dollarcoin",
"OKG": "OKG",
"ETHW": "Wrapped Ethereum",
"INJ": "Injective Protocol",
"MPLX": "MPLX",
"MIBR": "MiBR Fan Token",
"CO": "CO",
"AGLA": "Agrello",
"ROND": "RONDCOM",
"QMALL": "Qmall",
"PUMLX": "PUML Better Health",
"GCAKE": "Gourmet Galaxy",
"APT": "Alpha Token",
"APTC": "Alpha Token (Classic)",
"USDTEUR": "USD Coin",
"MTK": "Mtoken",
"MCRT": "Macro",
"MASK": "Mask Network",
"ECOX": "EcoX",
"HFT": "Huobi Token",
"KCAL": "KCAL Token",
"PEOPLE": "People",
"TWT": "Trust Wallet Token",
"ORT": "Orbit Token",
"HOOK": "HOOK Token",
"PRIMAL": "Primalbase Token",
"MCT": "MCT",
"OAS": "OAS Chain",
"MAGIC": "MAGIC",
"MEE": "MEE Token",
"TON": "TON Crystal",
"BONK": "BONK Token",
"FLR": "Flare",
"TIME": "Chrono.tech",
"3P": "3P",
"RPLUS": "Rplus",
"SSV": "SSV Chain",
"FXS": "Frax Share",
"CORE": "cvault.finance",
"RDNT": "RadonPay",
"BLUR": "Blur Network",
"LIS": "Wall Street Liscoin",
"AGIX": "SingularityNET",
"MDAO": "MasterDAO",
"ACS": "Aces",
"HVH": "HiveHaven",
"GNS": "Genesis Token",
"DPX": "DPX",
"PIP": "PiedPiperCoin",
"PRIME": "PrimeStone",
"EVER": "EverGrow",
"VRA": "Verasity",
"GPT": "GPTCash",
"FB": "Fiteeza",
"DZOO": "Deez Nuts",
"ID": "ID",
"ARBUSDC": "Arbitrum",
"ARBUSDT": "Arbitrum",
"XCAD": "CanadianDollar",
"MBX": "Mineral BioX",
"AXL": "Axie Infinity",
"CGPT": "Crypto Gaming Pool Token",
"PLAY": "HEROcoin",
"AGI": "SingularityNET",
"RLTM": "Relite Finance",
"SUI": "SuitCoin",
"SUIA": "SuitA",
"TAMA": "Tamago",
"MVL": "MVL",
"PEPE": "Pepe",
"LADYS": "Ladys",
"LMWR": "Lamaworld",
"BOB": "Bob's Repair",
"TOMI": "TomoChain",
"KARATE": "KarateCoin",
"SUIA": "SuitA",
"TURBOS": "Turbo Stash",
"FMB": "FMB",
"CAPO": "Capo",
"TENET": "Tenet",
"VELO": "VELO",
"ELDA": "Eldorado Token",
"CANDY": "Candy",
"FON": "Fon",
"OMN": "Omnitude",
"TOM": "TOM Token",
"MTC": "Mesh Network",
"VELA": "VelasPad",
"USDTBRZ": "Brazilian Digital Token",
"BTCBRZ": "Brazilian Digital Token",
"PENDLE": "Pendle",
"EGO": "Ethereum Gold",
"PEPE2": "PEPE2",
"NYM": "NYM",
"MNT": "Minter",
"MNT": "Minter",
"GSWIFT": "GoldSwift",
"SALD": "The Salamander",
"ARKM": "ARKM",
"NEON": "Neon",
"WLD": "World Token",
"WLD": "World Token",
"PLANET": "Planet",
"DSRUN": "Dark Shiba Run",
"SPARTA": "Spartan",
"TAVA": "Travala.com",
"SEILOR": "Seilor Finance",
"SEI": "Seigniorage",
"CYBER": "CYBER",
"ORDI": "OrionDollar",
"KAVA": "Kava",
"VV": "Vivid Value Token",
"SAIL": "SAIL",
"PYUSD": "PYUSDC",
"SOLEUR": "Solana",
"USDCEUR": "USD Coin",
"ADAEUR": "Cardano",
"DOGEEUR": "Dogecoin",
"LTCEUR": "Litecoin",
"XRPEUR": "XRP",
"ETHEUR": "Ethereum",
"BTCEUR": "Bitcoin",
"VEXT": "Vexanium",
"CTT": "CoinTransfer",
"NEXT": "NEXT",
"KAS": "Kas",
"NESS": "Crypto Ness",
"CAT": "Cat Token",
"FET": "Fetch.ai",
"LEVER": "Leverj",
"VEGA": "Vega Protocol",
"ZTX": "ZTX",
"JEFF": "Jeff",
"PPT": "Populous",
"TUSD": "TrueUSD",
"BEAM": "BEAM",
"POL": "Polkadot",
"TIA": "TIA Token",
"TOKEN": "Token",
"MEME": "Meme",
"SHRAP": "Shrapnel",
"RPK": "Rocket Pool",
"FLIP": "Unilayer",
"CRDS": "CREDITS",
"VRTX": "Vertex",
"ROOT": "Rootkit Finance",
"PYTH": "PYTH Network",
"MLK": "Milk Token",
"TVK": "ThetaFuel",
"KUB": "Kubocoin",
"5IRE": "5IRE",
"KCS": "KuCoin Token",
"VANRY": "Vanry",
"INSP": "InspectorCoin",
"JTO": "JustLiquidity",
"METH": "Methane",
"METHETH": "METH/ETH SLP",
"CBK": "Cobak Token",
"ZIG": "Zignaly",
"FMC": "Formacoin",
"MYRIA": "Myriad",


    

}

export default coinSpotBybit;