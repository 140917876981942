import React, { useState } from 'react';
import Search from './Search';

const Filters = ({ setSearchText, setCategory, page }) => {
    const categoryOptions = page === 1
        ? ['All', 'USDT Perpetual', 'USDC Perpetual', 'USDC Futures']
        : ['All', 'USDT', 'USDC', 'EUR', 'BTC', 'ETH', 'DAI', 'BRZ'];

    const [selectedCategory, setSelectedCategory] = useState('All');

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCategory(category);
    };

    return (
        <div className='w-full h-12 border-2 border-gray-1 rounded-lg flex items-center justify-between relative'>
            <Search setSearchText={setSearchText} />
            <div className="flex items-center mr-7">
                <label className="text-base font-medium mr-2">Trading Pair:</label>
                <select
                    className="p-1 border border-gray-1 rounded bg-gray-2 text-base"
                    value={selectedCategory}
                    onChange={(e) => handleCategoryChange(e.target.value)}
                >
                    {categoryOptions.map((category) => (
                        <option key={category} value={category} className="text-base">
                            {category}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default Filters;
