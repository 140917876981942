import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Filters from './Filters';
import coinLinearBybit from './coinLinearBybit';  // Import the exported coinName


const formatTurnover = (value) => {
  if (value === 'N/A' || isNaN(Number(value))) {
    return 'N/A';
  }

  const billion = 1000000000;
  const million = 1000000;

  const numericValue = Number(value);

  if (numericValue >= billion) {
    return `${(numericValue / billion).toFixed(2)}B`;
  } else if (numericValue >= million) {
    return `${(numericValue / million).toFixed(2)}M`;
  }

  return numericValue.toFixed(2);
};

const formatLastPrice = (value) => {
  if (value === 'N/A' || isNaN(Number(value))) {
    return 'N/A';
  }

  const numericValue = Number(value);
  const formattedValue = numericValue.toFixed(10);

  // Remove trailing zeros
  const strippedValue = formattedValue.replace(/\.?0*$/, '');

  return strippedValue;
};


const INTERVAL_DURATION = 100000; // 100 seconds
const alignInterval = 5 * 60 * 1000; // Align with intervals of 5 minutes

const TableData = () => {
  const [tableData, setTableData] = useState([]);
const [searchText, setSearchText] = useState("");
        const [selectedCategory, setCategory] = useState('All');
      const [sortColumn, setSortColumn] = useState('turnover_24h');
  const [sortDirection, setSortDirection] = useState('desc');



  const fetchData = async () => {
    try {
      const [tickerResponse, rsiResponse] = await Promise.all([
        axios.get('https://tradingbubbles.com/tickers'),
        axios.get('https://tradingbubbles.com/rsi')
      ]);

      if (tickerResponse.data && rsiResponse.data) {
        const tickers = tickerResponse.data;
        const rsiValues = rsiResponse.data || [];

        const dataWithRSI = tickers.map(ticker => {
          const rsiEntries = rsiValues.filter(rsi => rsi.symbol === ticker.symbol);
          const rsiValuesMap = {};
          
          rsiEntries.forEach(rsi => {
            rsiValuesMap[rsi.interval] = rsi.final_rsi;
          });

          return {
            ...ticker,
            rsi: rsiValuesMap,
          };
        });

        setTableData(dataWithRSI);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Fetch initial data
    fetchData();

    // Set up interval to fetch new data every 1 minute (60000 milliseconds)
    // Set up interval to fetch new data every specified interval duration
    const intervalId = setInterval(fetchData, INTERVAL_DURATION);

    // Clean up interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); 
    
    
const filteredData = tableData.filter((entry) => {
    const symbol = entry.symbol.toLowerCase();
    const name = coinLinearBybit[entry.symbol]?.toLowerCase() || '';

    const categoryFilter =
        selectedCategory === 'All' ||
        (selectedCategory === 'USDT Perpetual' && symbol.includes('usdt')) ||
        (selectedCategory === 'USDC Perpetual' && symbol.includes('perp')) ||
        (selectedCategory === 'USDC Futures' && !symbol.includes('usdt') && !symbol.includes('perp'));

    return (
        categoryFilter &&
        (symbol.includes(searchText.toLowerCase()) || name.includes(searchText.toLowerCase()))
    );
});
const generateLogoFileName = (symbol) => {
  // Extract the base symbol using match
  const baseSymbolMatch = symbol.match(/^[A-Za-z]+/);

  // Use the base symbol if available, otherwise use the entire symbol
  const baseSymbol = baseSymbolMatch ? baseSymbolMatch[0] : symbol;

  // Remove 'USDT' and 'PERP' from the cleaned symbol
  const cleanedSymbol = baseSymbol.replace(/USDT|PERP/g, '');

  // Append '_logo.png' to the cleaned symbol
  return `${cleanedSymbol}_logo.png`;
};

  const sortByColumn = (column) => {
    const sortFunctions = {
      turnover_24h: sortByVolume24,
      '5': () => sortByRSI('5'),
      '15': () => sortByRSI('15'),
      '30': () => sortByRSI('30'),
      '60': () => sortByRSI('60'),
      '240': () => sortByRSI('240'),
      'D': () => sortByRSI('D'),
      'W': () => sortByRSI('W'),
      'M': () => sortByRSI('M'),
    };

    const sortFunction = sortFunctions[column];
    if (sortFunction) {
      sortFunction();
    }
  };
    
  const sortByVolume24 = () => {
    const sortedData = tableData.slice().sort((a, b) => {
      const aVolume = a.turnover_24h || 0;
      const bVolume = b.turnover_24h || 0;
      return sortDirection === 'asc' ? aVolume - bVolume : bVolume - aVolume;
    });
    setTableData(sortedData);
  };    
    
const sortByRSI = (interval) => {
  const sortedData = tableData.slice().sort((a, b) => {
    const aRSI = a.rsi && a.rsi[interval] !== 'N/A' ? Number(a.rsi[interval]) : Number.POSITIVE_INFINITY;
    const bRSI = b.rsi && b.rsi[interval] !== 'N/A' ? Number(b.rsi[interval]) : Number.POSITIVE_INFINITY;

    if (isNaN(aRSI) && isNaN(bRSI)) {
      return 0; // Both values are 'N/A', keep them in the same order
    }

    if (isNaN(aRSI)) {
      return 1; // 'N/A' should be considered greater than any number
    }

    if (isNaN(bRSI)) {
      return -1; // Any number should be considered greater than 'N/A'
    }

    return sortDirection === 'asc' ? aRSI - bRSI : bRSI - aRSI;
  });

  setTableData(sortedData);
};



  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    sortByColumn(column);
  };
   
  const ArrowIcon = ({ direction }) => (
    <span className={`ml-1 ${direction === 'asc' ? 'rotate-180' : ''}`}>
      {direction === 'asc' ? '▲' : '▼'}
    </span>
  );          


  return (
      <>
            <Filters setSearchText={setSearchText} setCategory={setCategory} page={1}/>

    <div className='flex flex-col mt-9 border border-gray-1 rounded'>
      <table className='w-full table-auto'>
        <thead className='capitalize text-md text-gold font-medium border-b border-gray-1'>
          <tr>
            <th className='py-1'>#</th>
            <th className='py-1'>Coin Pair</th>
            <th className='py-1'>Name</th>
            <th className='py-1'>Price</th>
              <th className='py-1' onClick={() => handleSort('turnover_24h')}>
                Volume24 {sortColumn === 'turnover_24h' && <ArrowIcon direction={sortDirection} />}
              </th>
              <th className='py-1' onClick={() => handleSort('5')}>
                5m {sortColumn === '5' && <ArrowIcon direction={sortDirection} />}
              </th>
              <th className='py-1' onClick={() => handleSort('15')}>
                15m {sortColumn === '15' && <ArrowIcon direction={sortDirection} />}
              </th>
              <th className='py-1' onClick={() => handleSort('30')}>
                30m {sortColumn === '30' && <ArrowIcon direction={sortDirection} />}
              </th>
              <th className='py-1' onClick={() => handleSort('60')}>
                1h {sortColumn === '60' && <ArrowIcon direction={sortDirection} />}
              </th>
              <th className='py-1' onClick={() => handleSort('240')}>
                4h {sortColumn === '240' && <ArrowIcon direction={sortDirection} />}
              </th>
              <th className='py-1' onClick={() => handleSort('D')}>
                1D {sortColumn === 'D' && <ArrowIcon direction={sortDirection} />}
              </th>
              <th className='py-1' onClick={() => handleSort('W')}>
                1W {sortColumn === 'W' && <ArrowIcon direction={sortDirection} />}
              </th>
              <th className='py-1' onClick={() => handleSort('M')}>
                1M {sortColumn === 'M' && <ArrowIcon direction={sortDirection} />}
              </th>

          </tr>
        </thead>
        <tbody>
          {filteredData.map((entry, index) => (
            <tr
              key={index}
              className='text-center text-base border-b border-gray-1 hover:bg-gray-2 last:border-b-0'
            >
              <td>
  <div className="flex items-center pl-3">
    <span className='flex items-center'>
      <img className="w-[1.2rem] h-[1.2rem] mx-1.5" src={`./crypto_logos/${generateLogoFileName(entry.symbol)}`} alt={entry.symbol} />
    </span>
  </div>
</td>
              <td>{entry.symbol}</td>
              <td>{coinLinearBybit[entry.symbol] || 'N/A'}</td>
         <td>{entry.last_price ? `$${formatLastPrice(entry.last_price)}` : 'N/A'}</td>
<td>{entry.turnover_24h ? `$${formatTurnover(entry.turnover_24h)}` : 'N/A'}</td>


              <td className={
                entry.rsi && entry.rsi['5']
                  ? entry.rsi['5'] > 70
                    ? 'text-red'
                    : entry.rsi['5'] < 30
                      ? 'text-green'
                      : ''
                  : ''
              }>
                {entry.rsi && entry.rsi['5'] ? Number(entry.rsi['5']).toFixed(2) : 'N/A'}
              </td>
              <td className={
                entry.rsi && entry.rsi['15']
                  ? entry.rsi['15'] > 70
                    ? 'text-red'
                    : entry.rsi['15'] < 30
                      ? 'text-green'
                      : ''
                  : ''
              }>
                {entry.rsi && entry.rsi['15'] ? Number(entry.rsi['15']).toFixed(2) : 'N/A'}
              </td>
              <td className={
                entry.rsi && entry.rsi['30']
                  ? entry.rsi['30'] > 70
                    ? 'text-red'
                    : entry.rsi['30'] < 30
                      ? 'text-green'
                      : ''
                  : ''
              }>
                {entry.rsi && entry.rsi['30'] ? Number(entry.rsi['30']).toFixed(2) : 'N/A'}
              </td>
              <td className={
                entry.rsi && entry.rsi['60']
                  ? entry.rsi['60'] > 70
                    ? 'text-red'
                    : entry.rsi['60'] < 30
                      ? 'text-green'
                      : ''
                  : ''
              }>
                {entry.rsi && entry.rsi['60'] ? Number(entry.rsi['60']).toFixed(2) : 'N/A'}
              </td>
              <td className={
                entry.rsi && entry.rsi['240']
                  ? entry.rsi['240'] > 70
                    ? 'text-red'
                    : entry.rsi['240'] < 30
                      ? 'text-green'
                      : ''
                  : ''
              }>
                  {entry.rsi && entry.rsi['240'] ? Number(entry.rsi['240']).toFixed(2) : 'N/A'}
              </td>
              <td className={
                entry.rsi && entry.rsi['D']
                  ? entry.rsi['D'] > 70
                    ? 'text-red'
                    : entry.rsi['D'] < 30
                      ? 'text-green'
                      : ''
                  : ''
              }>
                  {entry.rsi && entry.rsi['D'] ? Number(entry.rsi['D']).toFixed(2) : 'N/A'}
              </td>
              <td className={
                entry.rsi && entry.rsi['W']
                  ? entry.rsi['W'] > 70
                    ? 'text-red'
                    : entry.rsi['W'] < 30
                      ? 'text-green'
                      : ''
                  : ''
              }>
                  {entry.rsi && entry.rsi['W'] ? Number(entry.rsi['W']).toFixed(2) : 'N/A'}
              </td>
              <td className={
                entry.rsi && entry.rsi['M']
                  ? entry.rsi['M'] > 70
                    ? 'text-red'
                    : entry.rsi['M'] < 30
                      ? 'text-green'
                      : ''
                  : ''
              }>{entry.rsi && entry.rsi['M'] ? Number(entry.rsi['M']).toFixed(2) : 'N/A'}
              </td>


            </tr>
          ))}
        </tbody>
      </table>
    </div>
</>
  );
};

export default TableData;