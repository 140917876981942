import React, { useState } from 'react';
import Logo from '../components/Logo';
import TableData from '../components/TableData';
import TableDataSpot from '../components/TableDataSpot';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const [showTableDataSpot, setShowTableDataSpot] = useState(false);

  const handleShowTableData = () => {
    setShowTableDataSpot(false);
  };

  const handleShowTableDataSpot = () => {
    setShowTableDataSpot(true);
  };

  return (
    <main className='w-full h-full flex flex-col first-letter: content-center items-center relative text-white font-nunito'>
      <div className='w-screen h-screen bg-gray-3 fixed -z-10' />
      <Logo />

      <div className='flex space-x-4 mt-4'>
        <button onClick={handleShowTableData}>Perpetual</button>
        <button onClick={handleShowTableDataSpot}>Spot</button>
      </div>

      <section className='w-[90%] h-[30%] mt-16 mb-24 relative'>
        {showTableDataSpot ? <TableDataSpot /> : <TableData />}
      </section>
    </main>
  );
};

export default Home;
