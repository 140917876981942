const coinLinearBybit={
    
"1000000VINUUSDT": "VINU",
"10000LADYSUSDT": "Lady Luck",
"10000NFTUSDT": "NFT",
"10000SATSUSDT": "Satoshi",
"10000STARLUSDT": "Starlink",
"1000BONKUSDT": "Bonk",
"1000BTTUSDT": "BitTorrent",
"1000FLOKIUSDT": "Floki",
"1000LUNCUSDT": "Lunar",
"1000PEPEUSDT": "Pepe",
"1000RATSUSDT": "Ratcoin",
"1000XECUSDT": "Xecoin",
"1INCHUSDT": "1inch",
"AAVEUSDT": "Aave",
"ACHUSDT": "Alchemy Pay",
"ADAUSDT": "Cardano",
"AERGOUSDT": "Aergo",
"AGIUSDT": "SingularityNET",
"AGIXUSDT": "SingularityDAO",
"AGLDUSDT": "Adventure Gold",
"AKROUSDT": "Akropolis",
"ALGOUSDT": "Algorand",
"ALICEUSDT": "MyNeighborAlice",
"ALPACAUSDT": "Alpaca Finance",
"ALPHAUSDT": "Alpha Finance Lab",
"AMBUSDT": "Ampleforth",
"ANKRUSDT": "Ankr",
"ANTUSDT": "Aragon",
"APEUSDT": "APEcoin",
"API3USDT": "API3",
"APTUSDT": "Apt Coin",
"ARBUSDT": "Ares Protocol",
"ARKMUSDT": "Ark Money",
"ARKUSDT": "Ark",
"ARPAUSDT": "ARPA Chain",
"ARUSDT": "Arweave",
"ASTRUSDT": "Astar Network",
"ATAUSDT": "Automata Network",
"ATOMUSDT": "Cosmos",
"AUCTIONUSDT": "Bounce Token",
"AUDIOUSDT": "Audius",
"AVAXUSDT": "Avalanche",
"AXLUSDT": "Axelar",
"AXSUSDT": "Axie Infinity",
"BADGERUSDT": "Badger DAO",
"BAKEUSDT": "BakeryToken",
"BALUSDT": "Balancer",
"BANDUSDT": "Band Protocol",
"BATUSDT": "Basic Attention Token",
"BCHUSDT": "Bitcoin Cash",
"BEAMUSDT": "BEAM",
"BELUSDT": "Bella Protocol",
"BICOUSDT": "Biconomy",
"BIGTIMEUSDT": "Big Time",
"BLURUSDT": "Blur Network",
"BLZUSDT": "Bluzelle",
"BNBPERP": "BNB Perpetual",
"BNBUSDT": "Binance Coin",
"BNTUSDT": "Bancor",
"BNXUSDT": "Binance X",
"BOBAUSDT": "Bobacoin",
"BONDUSDT": "BarnBridge",
"BSVUSDT": "Bitcoin SV",
"BSWUSDT": "BSWAP",
"BTC-05JAN24": "Bitcoin Futures",
"BTC-22DEC23": "Bitcoin Futures",
"BTC-23FEB24": "Bitcoin Futures",
"BTC-26JAN24": "Bitcoin Futures",
"BTC-28JUN24": "Bitcoin Futures",
"BTC-29DEC23": "Bitcoin Futures",
"BTC-29MAR24": "Bitcoin Futures",
"BTCPERP": "Bitcoin Perpetual",
"BTCUSDT": "Bitcoin",
"C98USDT": "Coin98",
"CAKEUSDT": "PancakeSwap",
"CEEKUSDT": "CEEK VR",
"CELOUSDT": "Celo",
"CELRUSDT": "Celer Network",
"CFXUSDT": "Conflux Network",
"CHRUSDT": "Chromia",
"CHZUSDT": "Chiliz",
"CKBUSDT": "Charm",
"COMBOUSDT": "Combo",
"COMPUSDT": "Compound",
"COREUSDT": "Cvault.finance",
"COTIUSDT": "COTI",
"CROUSDT": "Crypto.com Coin",
"CRVUSDT": "Curve DAO Token",
"CTCUSDT": "Cartesi",
"CTKUSDT": "CertiK",
"CTSIUSDT": "Cartesi",
"CVCUSDT": "Civic",
"CVXUSDT": "Convex Finance",
"CYBERUSDT": "CYBER",
"DARUSDT": "DarcMatter",
"DASHUSDT": "Dash",
"DENTUSDT": "Dent",
"DGBUSDT": "DigiByte",
"DODOUSDT": "DODO",
"DOGEUSDT": "Dogecoin",
"DOTUSDT": "Polkadot",
"DUSKUSDT": "Dusk Network",
"DYDXUSDT": "dYdX",
"EDUUSDT": "Educoin",
"EGLDUSDT": "Elrond",
"ENJUSDT": "Enjin Coin",
"ENSUSDT": "ENS",
"EOSUSDT": "EOS",
"ETCPERP": "Ethereum Classic Perpetual",
"ETCUSDT": "Ethereum Classic",
"ETH-05JAN24": "Ethereum Futures",
"ETH-22DEC23": "Ethereum Futures",
"ETH-23FEB24": "Ethereum Futures",
"ETH-26JAN24": "Ethereum Futures",
"ETH-28JUN24": "Ethereum Futures",
"ETH-29DEC23": "Ethereum Futures",
"ETH-29MAR24": "Ethereum Futures",
"ETHPERP": "Ethereum Perpetual",
"ETHUSDT": "Ethereum",
"ETHWUSDT": "Wrapped Ethereum",
"FETUSDT": "Fetch.ai",
"FILUSDT": "Filecoin",
"FITFIUSDT": "Fitmin Finance",
"FLMUSDT": "Flamingo",
"FLOWUSDT": "Flow",
"FLRUSDT": "Flare",
"FORTHUSDT": "Ampleforth Governance Token",
"FRONTUSDT": "Frontier",
"FTMUSDT": "Fantom",
"FUNUSDT": "FunFair",
"FXSUSDT": "Frax Share",
"GALAUSDT": "Gala",
"GALUSDT": "Galatasaray Fan Token",
"GASUSDT": "Gas",
"GFTUSDT": "GameFi",
"GLMRUSDT": "Glimmer",
"GLMUSDT": "Golem",
"GMTUSDT": "GoMining Token",
"GMXUSDT": "Gambit",
"GODSUSDT": "Olympus",
"GPTUSDT": "GPToken",
"GRTUSDT": "The Graph",
"GTCUSDT": "Gitcoin",
"HBARUSDT": "Hedera Hashgraph",
"HFTUSDT": "Hifi Finance",
"HIFIUSDT": "Hifi Token",
"HIGHUSDT": "Highstreet",
"HNTUSDT": "Helium",
"HOOKUSDT": "HOOK Token",
"HOTUSDT": "Holo",
"ICPUSDT": "Internet Computer",
"ICXUSDT": "ICON",
"IDEXUSDT": "IDEX",
"IDUSDT": "Everest",
"IGUUSDT": "IG Gold",
"ILVUSDT": "Illuvium",
"IMXUSDT": "Immutable X",
"INJUSDT": "Injective Protocol",
"IOSTUSDT": "IOST",
"IOTAUSDT": "IOTA",
"IOTXUSDT": "IoTeX",
"JASMYUSDT": "JasmyCoin",
"JOEUSDT": "JOE",
"JSTUSDT": "JUST",
"JTOUSDT": "Jupiter",
"KASUSDT": "Kasuma",
"KAVAUSDT": "Kava",
"KDAUSDT": "Kadena",
"KEYUSDT": "SelfKey",
"KLAYUSDT": "Klaytn",
"KNCUSDT": "Kyber Network",
"KSMUSDT": "Kusama",
"LDOUSDT": "Lido DAO Token",
"LEVERUSDT": "Leverj",
"LINAUSDT": "Linear",
"LINKUSDT": "Chainlink",
"LITUSDT": "Litentry",
"LOOKSUSDT": "LooksRare",
"LOOMUSDT": "Loom Network",
"LPTUSDT": "Livepeer",
"LQTYUSDT": "Liquity",
"LRCUSDT": "Loopring",
"LTCUSDT": "Litecoin",
"LUNA2USDT": "Terra",
"MAGICUSDT": "MAGIC",
"MANAUSDT": "Decentraland",
"MASKUSDT": "Mask Network",
"MATICPERP": "Polygon Perpetual",
"MATICUSDT": "Polygon",
"MAVUSDT": "Maverick Finance",
"MBLUSDT": "MovieBloc",
"MDTUSDT": "Measurable Data Token",
"MEMEUSDT": "Meme",
"MINAUSDT": "Mina Protocol",
"MKRUSDT": "Maker",
"MNTUSDT": "Minter",
"MOVRUSDT": "Moonriver",
"MTLUSDT": "Metal",
"MULTIUSDT": "Multi",
"MYRIAUSDT": "Myriad",
"NEARUSDT": "NEAR Protocol",
"NEOUSDT": "Neo",
"NKNUSDT": "NKN",
"NMRUSDT": "Numeraire",
"NTRNUSDT": "Neutron",
"OCEANUSDT": "Ocean Protocol",
"OGNUSDT": "Origin Protocol",
"OGUSDT": "ORG",
"OMGUSDT": "OMG Network",
"ONEUSDT": "Harmony",
"ONGUSDT": "Ontology Gas",
"ONTUSDT": "Ontology",
"OPPERP": "Optimism Perpetual",
"OPUSDT": "Opium",
"ORBSUSDT": "Orbs",
"ORDIUSDT": "Orion Protocol",
"OXTUSDT": "Orchid",
"PAXGUSDT": "Paxos Gold",
"PENDLEUSDT": "Pendle",
"PEOPLEUSDT": "People",
"PERPUSDT": "Perpetual Protocol",
"PHBUSDT": "PhoenixDAO",
"POLYXUSDT": "Polymath",
"POWRUSDT": "Power Ledger",
"PROMUSDT": "Prometeus",
"PYTHUSDT": "Pyth",
"QIUSDT": "QiSwap",
"QNTUSDT": "Quant",
"QTUMUSDT": "Qtum",
"RADUSDT": "Radicle",
"RAREUSDT": "SuperRare",
"RDNTUSDT": "RadonPay",
"REEFUSDT": "Reef Finance",
"RENUSDT": "Ren",
"REQUSDT": "Request",
"RIFUSDT": "RIF Token",
"RLCUSDT": "Refereum",
"RNDRUSDT": "Render Token",
"ROSEUSDT": "Oasis Network",
"RPLUSDT": "Rarible",
"RSRUSDT": "Reserve Rights",
"RSS3USDT": "RSS3",
"RUNEUSDT": "THORChain",
"RVNUSDT": "Ravencoin",
"SANDUSDT": "The Sandbox",
"SCRTUSDT": "Secret",
"SCUSDT": "Siacoin",
"SEIUSDT": "Seigniorage Shares",
"SFPUSDT": "SafePal",
"SHIB1000USDT": "Shiba Inu (1000x)",
"SKLUSDT": "SKALE Network",
"SLPUSDT": "Smooth Love Potion",
"SNTUSDT": "Status",
"SNXUSDT": "Synthetix",
"SOLPERP": "Solana Perpetual",
"SOLUSDT": "Solana",
"SPELLUSDT": "Spell Token",
"SSVUSDT": "SSV.Network",
"STEEMUSDT": "Steem",
"STGUSDT": "Stego",
"STMXUSDT": "StormX",
"STORJUSDT": "Storj",
"STPTUSDT": "Standard Tokenization Protocol",
"STRAXUSDT": "Stratis",
"STXUSDT": "Stacks",
"SUIUSDT": "Sushi",
"SUNUSDT": "Sun Token",
"SUPERUSDT": "SuperFarm",
"SUSHIUSDT": "SushiSwap",
"SWEATUSDT": "Sweatcoin",
"SXPUSDT": "Swipe",
"THETAUSDT": "Theta Token",
"TIAUSDT": "Troy",
"TLMUSDT": "Alien Worlds",
"TOKENUSDT": "Star Atlas",
"TOMIUSDT": "TomoChain",
"TONUSDT": "Tokamak Network",
"TRBUSDT": "Tellor",
"TRUUSDT": "TrueFi",
"TRXUSDT": "Tron",
"TUSDT": "TrueUSD",
"TWTUSDT": "Trust Wallet Token",
"UMAUSDT": "UMA",
"UNFIUSDT": "Uniswap Finance",
"UNIUSDT": "Uniswap",
"USDCUSDT": "USD Coin",
"USTCUSDT": "Wrapped UST",
"VETUSDT": "VeChain",
"VGXUSDT": "Voyager Token",
"VRAUSDT": "Verasity",
"WAVESUSDT": "Waves",
"WAXPUSDT": "WAX",
"WLDUSDT": "Wilder World",
"WOOUSDT": "Wootrade",
"WSMUSDT": "Wrapped Smash Token",
"XCNUSDT": "Crypton",
"XEMUSDT": "NEM",
"XLMUSDT": "Stellar",
"XMRUSDT": "Monero",
"XNOUSDT": "Xeno Token",
"XRDUSDT": "Radiant",
"XRPPERP": "XRP Perpetual",
"XRPUSDT": "XRP",
"XTZUSDT": "Tezos",
"XVGUSDT": "Verge",
"XVSUSDT": "Venus",
"YFIIUSDT": "DFI.Money",
"YFIUSDT": "yearn.finance",
"YGGUSDT": "Yield Guild Games",
"ZECUSDT": "Zcash",
"ZENUSDT": "Horizen",
"ZILUSDT": "Zilliqa",
"ZRXUSDT": "0x",
    

}

export default coinLinearBybit;