import React from 'react';
import { Link } from 'react-router-dom';
import logoSvg from "../assets/logo.svg";

const Logo = () => {
    return (
        <Link to="/" >
            <img className='absolute top-[1rem] left-[3rem] [text-decoration:none] flex item-center' src={logoSvg} alt="TradingBubbles" />
            <span className='absolute top-[2rem] left-[6.5rem] [text-decoration:none] text-gold text-lg flex item-center'>TradingBubbles</span>
        </Link>
    )
}

export default Logo